<template>
  <div class="table-responsive" v-if="list.length > 0">
    <table class="table table-striped table-hover" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id" @click="prepareInfo(row)">
          <td :data-label="$t('recipient')">{{ row.recipient }}</td>
          <td :data-label="$t('text')">{{ row.text }}</td>
          <td :data-label="$t('date')">{{ humanDateTime(row.create_date, $i18n.locale) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { useTableHandlers } from '@/application/composables/messagesTable.js'

export default {
  name: 'SentMessagesTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { humanDateTime } = useHumanDate()
    const i18n = useI18n()
    const { openModal } = useTableHandlers()
    const fields = ['recipient', 'text', 'date']
    const prepareInfo = (info) => {
      const obj = {
        recipient: info.recipient,
        text: info.text,
        mediaURL: info.media_url || '',
        status: i18n.t(`messagesStatus.${info.status}`),
        date: humanDateTime(info.create_date, i18n.locale),
      }
      openModal(obj)
    }
    return {
      humanDateTime,
      prepareInfo,
      fields,
    }
  },
}
</script>
